
import React from 'react'
import styled from 'styled-components'
import "../components/layout.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pedro from '../images/Pedro.jpg'
import Nuria from '../images/Nuria.jpg'
import Ana from '../images/Ana.jpg'


const QuemSomos = ({lightBg, imgStart, lightText, lightTextDesc, start, img, alt}) => {


  return (
    <> 
  <Layout>
    <SEO title="QuemSomos" />                         
    <div >
    <h2 className="titulo">ABOUT US</h2>

 <div className="texto">S3LF was born in early 2019 with the aim of transforming the perception of oriented physical activity, exploring not only its relevance in physical health but also in mental health.<br/>There are countless scientific studies already carried out that attest to the influence that regular practice of physical exercise can have on an individual's mental health. Our job is to organize this process in order to act on variables that may be underutilized and consequently create some disruption in an individual's natural functionality. The name S3LF was chosen to designate the 3 dimensions that we seek to exalt and develop: self-conscious, or self-awareness; self-improvement and self-sufficiency.<br/>The proposal is to provide each individual with a range of skills that helps them optimize all the resources at their disposal to achieve the best possible version of themselves. In order to achieve the purpose we set ourselves, we base our sessions on specific pillars:</div>

 
<div className="lista">
- Use of psychotherapeutic strategies<br/>
- Structured physical exercise<br/>
- Individual sessions or for groups homogeneous in the needs presented<br/>
- Constant monitoring<br/>
- Regular assessments<br/>
- Integration of yoga, pilates, and meditation techniques
</div>

<div className="texto">We believe that nowadays, our service will be an asset for any company that has a genuine interest in caring for the well-being of its employees, as it is now recognized the influence that this dimension has on the degree of satisfaction in the workplace and, consequently, in productivity.<br/>We have developed a method that we define as being psychophysiological, where the dimension of physical exercise is the stimulus to trigger transformations at the physical and cognitive level.</div>
</div>

    <InfoSec lightBg={lightBg=false}>
    <InfoRow imgStart={imgStart=true}>
<InfoColumn>
  <TextWrapper>
<Heading lightText={lightText=true}>Pedro Pereira</Heading>
<Subtitle lightTextDesc={lightTextDesc=true}>Graduated in Psychology from Instituto Superior da Maia and post-graduated in Sport, Exercise and Performance Psychology by the Portuguese Institute of Psychology.<br/> He began his professional career in the field of physical exercise in 2000, having worked with thousands of students for almost 21 years.<br/> The experience gained allowed him to observe the direct relationship between the guided practice of physical activity - the undeniable fruits in health - and the effect on the emotional and cognitive dimensions.<br/>As a specialist in physical exercise for 20 years, a trainer in the fitness area for 8 years, linking these skills to psychology has allowed the development of an intervention model geared towards success in understanding the individual as a whole.</Subtitle>
  </TextWrapper>
</InfoColumn>
<InfoColumn>
  <ImgWrapper start={'start'}>
  <Img src={Pedro} className='photo' alt={alt}/>
  </ImgWrapper>
</InfoColumn>
    </InfoRow>
    </InfoSec>
    <InfoSec lightBg={lightBg=true}>
    <InfoRow imgStart={imgStart=false}>
<InfoColumn>
  <TextWrapper>
<Heading lightText={lightText=false}>Núria Ferreira</Heading>
<Subtitle lightTextDesc={lightTextDesc=false}>Degree in Microbiology in Health from Universidade Católica Portuguesa in 2006.<br/>Since always a sports practitioner, an amateur hip-hop dancer, she started practicing Yoga in 2001.<br/>Graduated in Yoga in 2009 from the International Yoga University, soon starting her professional career in several gyms.<br/>She has become an undeniable asset to the project, integrating the company staff right from the start, due to the way it manages to connect with the people with whom she works, thus facilitating their development.</Subtitle>
  </TextWrapper>
</InfoColumn>
<InfoColumn>
  <ImgWrapper start={'start'}>
  <Img src={Nuria} className='photo' alt={alt}/>
  </ImgWrapper>
</InfoColumn>
    </InfoRow>
    </InfoSec>
    <InfoSec lightBg={lightBg=false}>
    <InfoRow imgStart={imgStart=true}>
<InfoColumn>
  <TextWrapper>
<Heading lightText={lightText=true}>Ana Gama</Heading>
<Subtitle lightTextDesc={lightTextDesc=true}>Graduated in Psychology and hasMaster in School Psychology and Education by the Instituto Universitário da Maia - ISMAI.<br/>Postgraduate, graduated (level one) in Emotional Intelligence and Specialized in Gender Equality (with complementary training in sexuality).<br/>Developed projects in the area of ​​cognitive stimulation and socio-emotional skills. Complementing her professional activities in research in her field of psychology.</Subtitle>
  </TextWrapper>
</InfoColumn>
<InfoColumn>
  <ImgWrapper start={'start'}>
  <Img src={Ana} className='photo' alt={alt}/>
  </ImgWrapper>
</InfoColumn>
    </InfoRow>
    </InfoSec>
    </Layout>
</>
  )
}

export default QuemSomos

const InfoSec = styled.div`
color: #fff;
padding: 100px 0;
background: ${({lightBg}) => (lightBg ? '#e7effc ' : '#4f79b8')};
`;

const InfoRow = styled.div`
display: flex;
margin: 0px 0px 0px 0px;
flex-wrap: wrap;
align-items: center;
flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
`;

const InfoColumn = styled.div`
margin-bottom: 0px;
padding-right: 75px;
padding-left: 75px;
flex: 1;
max-width: 50%;
flex-basis: 50%;


@media screen and (max-width: 768px) {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}
`;

const TextWrapper = styled.div`
max-width: 580px;
padding-top: 0;
padding-bottom: 40px;


@media screen and (max-width: 768px) {
  padding-bottom: 35px;
}
`;

const Heading = styled.div`
margin-bottom: 24px;
font-size: 40px;
line-height: 1.5;
color: ${({lightText}) => (lightText ? '#f7f8fa' : '#1e3966')};
`;

const Subtitle = styled.p`
max-width: 500px;
margin-bottom: 0px;
font-size: 18px;
line-height: 1.5;
color: ${({lightTextDesc}) => (lightTextDesc ? '#bfccdb' : '#1e3966')};
`;

const ImgWrapper = styled.div`
max-width: 610px;
display: flex;
justify-content: ${({start}) => (start ? 'flex-start' : 'flex-end')};
`

const Img = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-height: 500px;
`;

